import ReactDOM from 'react-dom';
import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ReactNotification from 'react-notifications-component';

import "@uiw/react-textarea-code-editor/dist.css";

import styles from './index.module.scss';

import Playground from './components/Playground';
import Footer from './components/Footer';
import SlackButton from './components/SlackButton';

function index () {
  return <div className={styles.installPage}>
    <h1>Introducing Pyroscope Slack Bot</h1>
    <p className={styles.subtitle}>Install Pyroscope Slack bot to share flamegraphs and profile scripts right from Slack</p>
    <p><SlackButton/></p>
    <div className={styles.flex}>
      <p className={styles.flex1}>
        <img width="720px" height="400" src="/assets/img/slackathon_blog_image_04-01.png"></img>
      </p>
      <p className={styles.flex1}>
        <iframe width="720px" height="400" src="https://www.youtube.com/embed/zy5W79Hbq4c?&autoplay=1" frameBorder="0" allowFullScreen={true}></iframe>
      </p>
    </div>
    <p><SlackButton/></p>
    <Footer/>
  </div>;
}

function success () {
  return <div className={styles.installPage}>
    <h1>Install Pyroscope Bot</h1>
    <p>Pyroscope Bot was installed successfully</p>
    <Footer/>
  </div>;
}

function error () {
  return <div className={styles.installPage}>
    <h1>Install Pyroscope Bot</h1>
    <p>Error happened when installing slack bot, try again</p>
    <p><SlackButton/></p>
    <Footer/>
  </div>;
}

function pg(){
  return <Playground/>;
}

ReactDOM.render(
  <>
    <ReactNotification />
    <BrowserRouter>
      <Routes>
        {
          function(){
            switch(window.location.host){
              // case "f:8000":
              case "playground.pyroscope.io":
                return [
                  <Route key="route-0" path="/" element={pg()}></Route>,
                  <Route key="route-1" path="/playground" element={pg()}></Route>,
                ];
              default:
                return [
                  <Route key="route-0" path="/" element={index()}></Route>,
                  <Route key="route-1" path="/success" element={success()}></Route>,
                  <Route key="route-2" path="/error" element={error()}></Route>,
                  <Route key="route-3" path="/playground" element={pg()}></Route>,
                ];
            }
          }()
        }

      </Routes>
    </BrowserRouter>
  </>,
  document.getElementById('root')
);
