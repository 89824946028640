import posthog from 'posthog-js';
let initialized = false;
let enabled = false;

function init() {
  if (initialized){
    return;
  }
  initialized = true;
  if (document && document.location && document.location.host && !document.location.host.endsWith(":8000")) {
    enabled = true;
    posthog.init('phc_Du0WoFm0TXxZlYn239Ar3YTefGjcuGKEp4oVDPDuFNZ', { api_host: 'https://app.posthog.com' });
  }
}

export default function trackEvent(eventName:string, properties:object) {
  init();
  if (enabled) {
    posthog.capture(eventName, properties);
  }
}
