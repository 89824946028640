import React from 'react';
import styles from './Footer.module.scss';

export default function Footer () {
  return <div className={styles.footer}>
    <div className={styles.footerLinks}>
      <a href="https://pyroscope.io/">pyroscope</a>
      <a href="https://pyroscope.io/terms">terms</a>
      <a href="https://pyroscope.io/privacy">privacy</a>
      <a href="mailto:contact@pyroscope.io">contact</a>
    </div>
    <div>
      created by <img src="https://pyroscope.io/img/logo-v3-small.png"></img> <a className={styles.twitterURL} href="https://twitter.com/pyroscopeio">@pyroscopeio</a>
    </div>
  </div>
}
