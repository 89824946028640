import React, { useEffect, useState } from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import {SelectionText} from '@uiw/react-textarea-code-editor';
import classnames from 'classnames';

import "@uiw/react-textarea-code-editor/dist.css";

import { store } from 'react-notifications-component';
import styles from './Playground.module.scss';
import Footer from './Footer';
import trackEvent from '../utils/analytics';


// @ts-expect-error: Until upstream is type checked this will have to do
import FlameGraphRenderer from '../../node_modules/pyroscope/webapp/javascript/components/FlameGraph/FlameGraphRenderer';
import { decodeFlamebearer } from '../../node_modules/pyroscope/webapp/javascript/models/flamebearer';

const initialCode = `// You can edit this code!
// Click here and start typing.
package main

import "time"
import "fmt"

func fibonacci(n int) int {
	if n < 2 {
		return n
	}
	return fibonacci(n-1) + fibonacci(n-2)
}

func main() {
	i := 0
	startTime := time.Now()
	for time.Since(startTime) < 5*time.Second {
		i++
		if i < 100 {
			fmt.Printf("%d %d\\n", i, fibonacci(i))
		}
	}
}

`;

function Spinner(props:any){
  return <div className={"spinner"+(props.disabled ? " disabled": "")}></div>;
}

export default function Playground () {
  const [code, setCode] = useState<any | undefined>(initialCode);
  const [profile, setProfile] = useState<any | undefined>();
  const [status, setStatus] = useState<any | undefined>();
  const [errorMsg, setErrorMsg] = useState<any | undefined>();
  const [stdout, setStdout] = useState<any | undefined>();

  useEffect(() => {
    document.title = `Pyroscope Go Playground`;
  });


  function formatCode(code:string){
    return code;
  }
  function runCode(code:string){
    trackEvent('run_code', {});
    setStatus("loading");
    fetch("/playground/run-code",{
      method: 'post',
      body: code,
      headers: {
        "Content-Type": "text/plain"
      }
    }).then(function(response) {
      if (response.ok) {
        return response.json()
      } else {
        return response.text().then((text) => {
          var jsonMsg = text;
          try {
            var json = JSON.parse(text);
            if (json.CompileError){
              jsonMsg = "compilation error:\n"+json.CompileOutput
            }
          } catch (e) {

          }
          throw new Error(jsonMsg);
        });
      }
    }).then(function(response) {
      trackEvent('run_code_success', {});
      // Duration    time.Duration
      // ProfileJSON string
      // PyroscopeOutput string
      // CompileOutput string
      // CompileError  string
      // ProgramOutput string
      // LambdaOutput string

      setProfile(decodeFlamebearer(JSON.parse(response.ProfileJSON)));
      setStdout(response.ProgramOutput);
      setStatus("success");
    }).catch(function(error) {
      trackEvent('run_code_error', {});
      setStatus("error");
      setErrorMsg(error.message);
      store.addNotification({
        title: 'Error',
        message: error.message,
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 4000,
          onScreen: true,
        },
      });
    })
  }

  return <div className={styles.playground}>
    {/* <header className={styles.header}>
      <img className={styles.logo} src="https://pyroscope.io/img/logo-v3-small.png"></img>
      <div className={styles.spacer}></div>
      <div className={styles.headerLinks}>
        <a className={classnames(styles.headerLink, styles.active)} href="./">Playground</a>
        <a className={styles.headerLink} href="https://pyroscope.io/">pyroscope</a>
        <a className={styles.headerLink} href="https://pyroscope.io/">pyroscope</a>
      </div>
    </header> */}
    <div className={styles.toolbar}>
      {/* <div className={styles.toolbarLeft}>
        <button>
          <FontAwesomeIcon icon={faArrowLeft} />
          Home
        </button>
      </div> */}
      <div className={styles.toolbarLeft}>
        {/* <img src="https://pyroscope.io/img/logo-v3-small.png"></img> */}
        <h1>
          Pyroscope Go Playground
        </h1>
      </div>
      <div className={styles.toolbarRight}>
        <Spinner disabled={status !== "loading"}></Spinner>
        <button disabled={status == "loading"} className={styles.primary} onClick={function(){runCode(code)}}>Run</button>
        {/* <button onClick={function(){runCode(code)}}>Share</button> */}
      </div>
    </div>
    <div className="panes panes-hor">
      <div className="pane panes-ver">
        <div className="pane editor">
          <CodeEditor
            value={initialCode}
            language="go"
            placeholder="Please enter Go code."
            onChange={(evn) => setCode(evn.target.value)}
            padding={15}
            onKeyDown={(e) => {
              // fix for discrepancy between react and native event
              e.code = e.nativeEvent.code;


              const api = new SelectionText(e.target as HTMLTextAreaElement);
              if (e.code && e.code.toLowerCase() === 'tab') {
                e.stopPropagation();
                e.preventDefault();
                if (api.start === api.end) {
                  api.insertText('\t').position(api.start + 1, api.end + 1);
                } else if (api.getSelectedValue().indexOf('\n') > -1 && e.shiftKey) {
                  api.lineStarRemove('\t');
                } else if (api.getSelectedValue().indexOf('\n') > -1) {
                  api.lineStarInstert('\t');
                } else {
                  api.insertText('\t').position(api.start + 1, api.end);
                }
                api.notifyChange();
                return false;
              }
              return true;
            }}
            style={{
              fontSize: 14,
              backgroundColor: "#f5f5f5",
              fontFamily: 'SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace',
            }}
          />
        </div>
        {/* <div className="pane result">{testFlamegraph()}</div> */}
        <code className="pane stdout">{
          function(){
            switch(status){
              case "success":
                return stdout;
              case "loading":
                return "Waiting for remote server...";
              case "error":
                return errorMsg;
              default:
                return "Click Run to see the result.";
            }
          }()
        }</code>
      </div>
      <div className="pane flamegraph">{
        function(){
          switch(status){
            case "success":
              return <FlameGraphRenderer
                flamebearer={profile}
                // rawFlamegraph={data.raw}
                viewType="single"
                display="flamegraph"
                ExportData={<div />}
                showToolbar={true}
              />;
            case "loading":
              return <div className="flamegraph-message">Waiting for remote server...</div>;
            default:
              return <div className="flamegraph-message">Click Run to see the result</div>;
          }
        }()
      }</div>
    </div>
    <Footer/>
  </div>;
}
